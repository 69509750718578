




























































import ModuleElectionApi from "@/mixins/ModuleElectionApi";
import ModuleStore from "@/store/modules/ModuleStore";
import UserStore from "@/store/modules/UserStore";
import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

@Component
export default class Module extends Vue {
  @Prop() moduleNo!: string;
  @Prop() color!: string;
  @Prop() semester!: number;
  @Prop() beyondSemester!: string;
  @Prop() mokuPdfUrl!: string;
  moduleStore: ModuleStore = getModule(ModuleStore);
  userStore: UserStore = getModule(UserStore);
  moduleElectionApi = new ModuleElectionApi();

  mvpLink(moduleResponsible: string): string {
    return "https://www.zhaw.ch/=" + moduleResponsible;
  }

  moduleResponsibleSeparator(index: number): string {
    return index > 0 ? ", " : "";
  }

  get moduleResponsible(): string[] {
    return this.moduleStore.getModuleResponsible(this.moduleNo).split(",");
  }

  get location(): string {
    return this.moduleStore.moduleLocation(this.moduleNo);
  }

  get executionSemester(): string {
    let executionSemester = "HS";
    if (this.semester % 6 === 0) {
      executionSemester = "FS";
    }
    return executionSemester;
  }

  get moduleSelected(): boolean {
    return this.moduleStore.isModuleSelected(this.moduleNo);
  }

  set moduleSelected(selected: boolean) {
    if (this.userStore.isStudent) {
      this.moduleStore.updateElection(this.moduleNo);
    } else {
      let moduleNos: Array<string> = []
      this.moduleStore.electedModules.forEach(element => moduleNos.push(element.id));
      if (selected) {
        moduleNos.push(this.moduleNo)
      } else {
        moduleNos = moduleNos.filter((e, i) => e !== this.moduleNo)
      }
      this.moduleElectionApi.validateElectionDemo(moduleNos).then(value => this.moduleStore.setElectionData(value));
    }
  }
}
