











































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SubjectInfoModal from "@/components/SubjectInfoModal.vue";
import ModuleElection from "@/components/ModuleElection.vue";
import ColumnsBlock from "@/components/ColumnsBlock.vue";
import ModuleStore from "@/store/modules/ModuleStore";
import "vue-class-component/hooks";
import UserStore from "@/store/modules/UserStore";
import Module from "@/components/Module.vue";
import IModule from "@/models/module";
import ModuleCategory from "@/models/moduleCategory";
import Student from "@/models/student";
import PageText from "@/models/pageText";
import PageTextApi from "@/mixins/PageTextApi";
import ModuleFilter from "@/components/ModuleFilter.vue";
import _ from "lodash";
import InstructionsModal from "@/components/InstructionsModal.vue";
import ModuleElectionApi from "@/mixins/ModuleElectionApi";

interface ElectionCategoryMap {
  [index: string]: string;
}

@Component({
  components: {
    SubjectInfoModal,
    ModuleElection,
    ColumnsBlock,
    Module,
    ModuleFilter,
  },
})
export default class Homepage extends Vue {
  selectedModule: Module | null = null;
  isModalActive = false;
  moduleStore: ModuleStore = getModule(ModuleStore);
  userStore: UserStore = getModule(UserStore);
  moduleElectionApi: ModuleElectionApi = new ModuleElectionApi();
  openCategories = [true, true, true, true];
  headerText = "";
  footerText = "";

  created(): void {
    document.title = "Homepage";
  }

  beforeMount(): void {
    this.setSiteTexts();
    if (this.userStore.isAuthenticated && !this.userStore.isStudent) {
      this.moduleElectionApi.validateElectionDemo([]).then(value => this.moduleStore.setElectionData(value));
    }
  }

  get student(): Student | null {
    return this.userStore.student;
  }

  @Watch("userStore.isStoreInitialized")
  async onUserStoreInitialization(): Promise<void> {
    if (this.userStore.isStoreInitialized) {
      await this.setSiteTexts();
    }
  }

  @Watch("userStore.isStudent")
  onStudentLogin(): void {
    if (
      this.userStore.student &&
      this.userStore.student.canElect &&
      !this.moduleStore.isClientConnected
    ) {
      this.moduleStore.createConnection();
    }
  }

  toggleCategory(index: number): void {
    this.$set(this.openCategories, index, !this.openCategories[index]);
  }

  async setSiteTexts(): Promise<void> {
    let texts: Array<PageText> = [];
    const api = new PageTextApi();
    try {
      if (this.userStore.isAuthenticated && this.userStore.student) {
        const student = this.userStore.student;
        if (student.tz && student.secondElection) {
          texts = await api.getPartTimeSecondElectionText();
        } else if (student.tz) {
          texts = await api.getPartTimeFirstElection();
        } else {
          texts = await api.getFullTimePageText();
        }
      } else if (
        (this.userStore.isAuthenticated && !this.userStore.isStudent) ||
        !this.userStore.isAuthenticated
      ) {
        texts = await api.getAnonymousPageText();
      }
    } catch (e) {
      const error = {
        text: "<h1 class='title'>Es konnten keine Daten aus dem Backend empfangen werden</h1>",
        textNumber: 0,
      };
      texts.push(error as PageText);
      // already treated by error handler
    }
    const header = texts.find((text) => text.textNumber === 0);
    const footer = texts.find((text) => text.textNumber === 1);
    if (header) this.headerText = header.text;
    if (footer) this.footerText = footer.text;
  }

  getElectionCategoryMap(): ElectionCategoryMap {
    const electionCategoryMap: ElectionCategoryMap = {};
    electionCategoryMap[ModuleCategory.CONSECUTIVE_MODULE] =
      "Konsekutive Wahlpflichtmodule IT6";
    electionCategoryMap[ModuleCategory.SUBJECT_MODULE] =
      "Fachliche Wahlpflichtmodule IT6";
    electionCategoryMap[ModuleCategory.INTERDISCIPLINARY_MODULE] =
      "Studiengangübergreifende Wahlpflichtmodule IT6";
    electionCategoryMap[ModuleCategory.CONTEXT_MODULE] =
      "Kontext Wahlpflichtmodule IT5";

    return electionCategoryMap;
  }

  getModulesByCategory(category: ModuleCategory): Array<IModule> {
    let modules: Array<IModule> = [];
    const filteredModules = this.moduleStore.moduleArr.filter(
      (module: IModule) =>
        module.category === category && module.consecutiveModuleNo === ""
    );
    if (filteredModules.length > 0) {
      modules = filteredModules;
    } else {
      modules = this.moduleStore.moduleArr.filter(
        (module: IModule) => module.consecutiveModuleNo !== ""
      );
    }

    if (this.moduleStore.isFilterActive) {
      modules = _.remove(modules, (module: IModule) => {
        return this.moduleStore.filteredModules.indexOf(module) !== -1;
      });
    }

    return modules;
  }

  showAdditionalSubjectInfo(module: Module): void {
    this.selectedModule = module;
    this.isModalActive = !this.isModalActive;
  }

  showInstructions(): void {
    this.$buefy.modal.open({
      component: InstructionsModal,
      parent: this,
      hasModalCard: true,
      canCancel: true,
      props: { student: this.userStore.student, allowClose: true },
    });
  }
}
