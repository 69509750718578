



































































































import ModuleElectionApi from "@/mixins/ModuleElectionApi";
import StudentApi from "@/mixins/StudentApi";
import Student from "@/models/student";
import ModuleStore from "@/store/modules/ModuleStore";
import UserStore from "@/store/modules/UserStore";
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

@Component
export default class InstructionsModal extends Vue {
  @Prop() student!: Student;
  @Prop() allowClose!: boolean;
  userStore = getModule(UserStore);
  studentApi = new StudentApi();
  moduleElectionApi = new ModuleElectionApi();

  async close(): Promise<void> {
    this.$emit("close");
  }

  async openSettings(): Promise<void> {
    const student = this.userStore.student;
    const moduleStore = getModule(ModuleStore);
    try {
      if (student) {
        this.moduleElectionApi.triggerValidationUpdate(student.email);
        const data = await this.moduleElectionApi.getElectionStructure(
          student.email
        );
        moduleStore.setElectionData(data);
        this.$router.push({ name: "Settings" });
      }
    } catch (e) {
      // handled by error handler
    }
    this.$emit("close");
  }
}
