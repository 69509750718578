






















import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Header from "@/components/site/Header.vue";
import Footer from "@/components/site/Footer.vue";
import UserStore from "@/store/modules/UserStore";
import "vue-class-component/hooks";
import ModuleStore from "./store/modules/ModuleStore";
import InstructionsModal from "@/components/InstructionsModal.vue";
import Student from "./models/student";

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class App extends Vue {
  userStore = getModule(UserStore);
  moduleStore = getModule(ModuleStore);

  async mounted(): Promise<void> {
    await this.userStore.initUserStore();
    this.moduleStore.initModuleSelection();
    this.moduleStore.updateModules();
    const student = this.userStore.student;
    if (
      this.userStore.isAuthenticated &&
      student &&
      !student.settingsConfirmed
    ) {
      this.showInstructions(student);
    }
  }

  destroyed(): void {
    this.moduleStore.closeStompConnection();
  }

  showInstructions(student: Student): void {
    this.$buefy.modal.open({
      component: InstructionsModal,
      parent: this,
      hasModalCard: true,
      canCancel: false,
      props: { student: student, allowClose: false },
    });
  }
}
