





















































































import { Component, Vue } from "vue-property-decorator";
import "vue-class-component/hooks";
import { getModule } from "vuex-module-decorators";
import UserStore from "@/store/modules/UserStore";
import ModuleStore from "@/store/modules/ModuleStore";
import ColumnsBlock from "@/components/ColumnsBlock.vue";
import ElectionStructureElement from "@/models/electionStructureElement";
import ModuleCategory from "@/models/moduleCategory";
import ElectionStatus from "@/models/electionStatus";

interface ModuleTile {
  moduleName: string;
  moduleColor: string;
  semester: number;
}

@Component({
  components: {
    ColumnsBlock,
  },
})
export default class ModuleElection extends Vue {
  moduleStore: ModuleStore = getModule(ModuleStore);
  userStore: UserStore = getModule(UserStore);
  isElectionInfoOpen = true;

  getTilesForSemester(semester: number): Array<ModuleTile> {
    const tiles: Array<ModuleTile> = [];

    if (this.moduleStore.electedModules) {
      const sortOrder = [
        ModuleCategory.CONTEXT_MODULE,
        ModuleCategory.PROJECT_MODULE,
        ModuleCategory.BACHELOR_MODULE,
        ModuleCategory.CONTEXT_MODULE,
        ModuleCategory.SUBJECT_MODULE,
        ModuleCategory.INTERDISCIPLINARY_MODULE,
      ];
      this.moduleStore.electedModules
        .filter((element) => element.semester % semester === 0)
        .sort(
          (el1, el2) =>
            sortOrder.indexOf(el1.category) - sortOrder.indexOf(el2.category)
        )
        .map((element) => this.electionStructureElementToTile(element))
        .forEach((tile) => tiles.push(tile));
    }

    return tiles;
  }

  getSemesterTitle(semester: number): string {
    if (semester == 5 || semester == 7) {
      return "Herbstsemester";
    } else if (semester == 6 || semester == 8) {
      return "Frühlingssemester";
    } else {
      return "";
    }
  }

  get overflowedElectionTiles(): Array<ModuleTile> {
    const tiles: Array<ModuleTile> = [];
    if (this.moduleStore.overflowedElectedModules) {
      this.moduleStore.overflowedElectedModules
        .map((element) => this.electionStructureElementToTile(element))
        .forEach((tile) => tiles.push(tile));
    }
    return tiles;
  }

  get semesters(): Array<number> {
    const student = this.userStore.student;
    let semesters = [6, 5];
    if (student && student.tz && student.secondElection) {
      semesters = [8, 7];
    }
    return semesters;
  }

  electionStructureElementToTile(
    element: ElectionStructureElement
  ): ModuleTile {
    const title = this.mapModuleTitle(element.id, element.name);
    return {
      semester: element.semester,
      moduleName: title,
      moduleColor: this.moduleStore.getColorForCategory(element.category),
    };
  }

  mapModuleTitle(moduleNo: string, title: string): string {
    const module = this.moduleStore.moduleArr.find(
      (module) => module.moduleNo === moduleNo && module.language === "Englisch"
    );
    if (module) {
      title = title.concat(" (E)");
    }
    return title;
  }

  get electionStatus(): Array<string> {
    const electionStatus: Array<string> | null =
      this.moduleStore.messages;
    const reasons: Array<string> = [];

    reasons.push(...electionStatus)

    if (reasons.length == 0) {
      reasons.push("Ihre Wahl ist im Moment gültig");
    }
    return reasons;
  }

  get electionStatusColor(): string {
    let currentPriorStatus = -1

    for (const electionGroupStatus of this.electionGroupsStatus) {
      const electionStatePriority = this.getElectionStatePriority(electionGroupStatus.electionState);
      if (electionStatePriority > currentPriorStatus) currentPriorStatus = electionStatePriority
    }


    switch (currentPriorStatus) {
      case 1:
        return "has-background-danger";
      case 0:
        return "has-background-warning";
      default:
        return "has-background-success";
    }
  }

  get electionGroupsStatus(): Array<ElectionStatus> {
    return this.moduleStore.electionGroupsStatus;
  }

  getElectionStateColor(state: string): string {
    if (state == "unvollständig") return "is-warning"
    if (state == "vollständig") return "is-success"
    return "is-danger"
  }

  getElectionStatePriority(state: string): number {
    if (state == "ungültig") return 1
    if (state == "unvollständig") return 0
    return -1
  }
}
