





































import { Vue, Component, Prop, PropSync, Watch } from "vue-property-decorator";
import Module from "@/models/module";
import EventoData from "@/models/eventoData";
import ModuleApi from "@/mixins/ModuleApi";

@Component
export default class SubjectInfoModal extends Vue {
  @Prop() module!: Module;
  @PropSync("isModalActive") syncedIsModalActive!: boolean;
  eventoData: EventoData | null = null;
  moduleApi = new ModuleApi();
  fieldTitles = {
    shortDescription: "Kurzbeschreibung",
    coordinator: "Modulverantwortlicher",
    learningObjectives: "Lernziele",
    moduleContents: "Modulinhalte",
    literature: "Lehrmittel",
    suppLiterature: "Ergänzende Literatur",
    prerequisites: "Zulassungsvoraussetzungen",
    moduleStructure: "Modulausprägung",
    exams: "Leistungsnachweise",
    remarks: "Bemerkungen",
  };

  @Watch("module")
  async updateInfoModalContent(): Promise<void> {
    try {
      if (this.module) {
        this.eventoData = await this.moduleApi.getEventoDataById(
          this.module.moduleNo
        );
      }
    } catch (e) {
      console.log(e);
    }
  }
}
